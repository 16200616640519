<template>
    <Cooperado v-if="!isAlterar" />
    <CooperadoForm v-if="isAlterar" :matricula="matricula" />
</template>

<script lang="js">
    import Cooperado from '../../components/cadastro/Cooperado.vue'
    import CooperadoForm from '../../components/cadastro/CooperadoForm.vue';

    export default {
        name: 'Cooperados',
        components: { Cooperado, CooperadoForm },
        data() {
            return {
                isAlterar: false,
                matricula: null,
            };
        },
        created() {
            this.validarRotas();
        },
        updated() {
            this.validarRotas();
        },
        methods: {
            validarRotas() {
                let nomeRota = this.$route.name;
                if (nomeRota == 'alterarCooperado') {
                    this.isAlterar = true;
                    this.matricula = this.$route.params.matricula;
                } else {
                    this.isAlterar = false;
                    this.matricula = null;
                }
            }
        },
    }

</script>
