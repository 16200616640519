import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/cidade`;

class CidadeService {

    getCidadePorCodigo(codigoCidade) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${codigoCidade}`);
    }  

    getCidadePorNome(nome) {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/buscar/${nome}`);
    }

    buscarTodasCidades() {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}`);
    }
}

export default new CidadeService();
