<template>
    <Dialog
        v-model:visible="visivel"
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '70vw'}"
        :maximizable="true"
        :modal="true"
        @show="carregarHistoricoAcessos()"
        @hide="$emit('fechar')"
    >
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-book mr-1" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">Histórico de Acesso</h3>
            </div>
        </template>
        <div class="grid">
            <div class="col-12">
                <DataTable 
                    responsiveLayout="scroll"
                    scrollDirection="both"
                    showGridlines
                    :value="historicoAcessos"
                >
                    <template #empty>
                        Nenhum registro encontrado.
                    </template>

                    <template #loading>
                        Carregando... Por favor, aguarde.
                    </template>

                    <Column field="data" header="Data" style="width:7rem; text-align: center;">
                        <template #body="{ data }">
                            {{ formatDate(data.data, 'DD/MM/YYYY') }}
                        </template>
                    </Column>
                    <Column field="hora" header="Hora" style="width:5.5rem; text-align: center;">
                        <template #body="{ data }">
                            {{ formatDate(data.data, 'hh:mm') }}
                        </template>
                    </Column>
                    <Column field="web" header="Canal" style="width:5.5rem; text-align: center;">
                        <template #body="{ data }">
                            {{data.web == true ? 'Portal web' : 'Aplicativo mobile'}}
                        </template>
                    </Column>
                    <Column field="ip" header="IP" style="width:5.5rem;" />
                </DataTable>
            </div>
        </div>
        <template #footer>
            <Paginator
                :rows="qtdRegistro"
                :totalRecords="totalRegistro"
                :rowsPerPageOptions="[3, 5, 10, 20, 30]"
                @page="onPage"
            ></Paginator>
        </template>
    </Dialog>
</template>

<script>
    import Formatacao from '../../utilities/Formatacao';
    import CooperadoService from '../../service/CooperadoService';

    export default {
        emits: ['fechar'],
        props: {
			matricula: {
				type: Number,
				required: true
			},
            visivel: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                totalRegistro: 0,
                qtdRegistro: 5,
                pagina: 0,
                historicoAcessos: [],
                loading: false,
            }
        },
        methods: {
            carregarHistoricoAcessos() {
                this.carregarLoading();
                this.historicoAcessos = [];
                if(!this.visivel) return;
                CooperadoService.buscarHistoricoAcessos(this.matricula, this.pagina, this.qtdRegistro)
                    .then(({ data }) => {
                        if(data) {
                            this.historicoAcessos = data.content;
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalPages * this.qtdRegistro;
                            this.carregarLoading();
                        }
                    })
                    .catch(error => {
                        this.historicoAcessos = [];
                        this.qtdRegistro = 0;
                        this.totalRegistro = 0;
                    });
            },

            onPage(event) {
                this.pagina = event.page;
                this.qtdRegistro = event.rows;
                this.carregarHistoricoAcessos();
            },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },

            carregarLoading() {
                this.loading = !this.loading;
            },
        }
    }
</script>
