<template>
    <div class="card">
        <Toast />
        <h5>Alterar Cooperados</h5>

        <div class="col-12 sm:col-12 lg:col-8 border-panel">
            <div class="grid">
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="matricula"><strong>Matricula: </strong>{{cooperado.matricula}}</label>
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="nome"><strong>Nome: </strong>{{cooperado.nome}}</label>
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="dataCadastro"><strong>Data de Cadastro: </strong>{{formatDate(cooperado.dataCadastro)}}</label>
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="email"><strong>E-mail <span style="color: red;">*</span></strong></label>
                    <InputText
                        :class="['w-full', 'mt-1', {'p-invalid': emailInvalido}]"
                        id="email"
                        v-model="cooperado.email" />
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="celular"><strong>Celular <span style="color: red;">*</span></strong></label>
                    <InputMask
                        :class="['w-full', 'mt-1', {'p-invalid': celularInvalido}]"
                        id="celular"
                        v-model="cooperado.celular"
                        mask="(99) 99999-9999" />
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="cidadePrevisaoTempo"><strong>Previsão de Tempo</strong></label><br />
                    <MultiSelect v-model="cooperadoCidadesPrevisaoTempo" 
                            :options="cidadesPrevisaoTempo" 
                            optionLabel="nome" 
                            placeholder="Cidades" 
                            :filter="true" 
                            class="multiselect-custom">
                        <template #value="slotProps">
                            <div class="country-item country-item-value" v-for="cidade of slotProps.value" :key="cidade.id">
                                <div>{{cidade.nome}}</div>
                            </div>
                            <template v-if="!slotProps.value || slotProps.value.length === 0">
                                Selecionar Cidades
                            </template>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item">
                                <div>{{slotProps.option.nome}}</div>
                            </div>
                        </template>
                    </MultiSelect>
                </div>
                <div class="break-flex-row"></div>
                <div class="col-8 sm:col-9 lg:col-2">
                    <label for="ativo"><strong>Ativo</strong></label>
                    <Dropdown class="w-full mt-1" id="ativo" v-model="ativo" :options="ativoOpcoes" optionLabel="nome" />
                </div>
                <div class="col-8 sm:col-9 lg:col-3">
                    <label for="senha"><strong>Senha</strong></label>
                    <InputText
                        type="password" 
                        :class="['w-full', 'mt-1', {'p-invalid': senhaInvalida}]"
                        id="senha"
                        v-model="senha" />
                </div>
                <div class="col-8 sm:col-9 lg:col-3">
                    <label for="repetirSenha"><strong>Repetir Senha</strong></label>
                    <InputText
                        type="password" 
                        :class="['w-full', 'mt-1', {'p-invalid': confirmarSenhaInvalida}]"
                        id="repetirSenha"
                        v-model="confirmarSenha" />
                </div>
            </div>
            <br />

            <div class="flex align-items-center">
                <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2" @click="dialog = true;" />
                <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-success" @click="salvar()" />
            </div>
        </div>
    </div>

    <Dialog v-model:visible="dialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text p-button-danger" @click="dialog = false;" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="voltar()" />
        </template>
    </Dialog>
</template>

<script>
    import CooperadoService from '../../service/CooperadoService';
    import CidadeDropDown from '../CidadeDropDown.vue';
    import Formatacao from '../../utilities/Formatacao';
    import CooperadoCidadePrevisaoTempoService from '../../service/CooperadoCidadePrevisaoTempoService';
    import CidadeService from '../../service/CidadeService';

    export default {
        name: 'Cooperados',
        props: {
            matricula: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                cooperado: {},
                dialog: false,

                ativo: null,
                ativoOpcoes: [
                    {nome: 'Sim', valor: true},
                    {nome: 'Não', valor: false}
                ],
                
                emailInvalido: false,
                celularInvalido: false,
                senha: '',
                confirmarSenha: '',
                senhaInvalida: false,
                confirmarSenhaInvalida: false,

                toastTipoErro: 'error',
                toastTituloFalha: 'Falha',
                toastTipoSucesso: 'success',
                toastTituloSucesso: 'Sucesso',
                toastLife: 3000,
                toastDetalheAtuazlizacaoSucesso: 'O cooperado foi atualizado com sucesso!',
                toastDetalheAtualizacaoFalha: 'Falha ao atualizar o cooperado!',

                cidadesPrevisaoTempo: [],
                cooperadoCidadesPrevisaoTempo: null,
            }
        },
        created() {
            this.consultarCooperado();
            this.buscarTodasCidades();
            this.buscarCooperadoCidadesPrevisaoTempo();
        },
        methods: {
            consultarCooperado() {
                CooperadoService.buscarCooperado(this.matricula)
                    .then(({ data }) => {
                        if (data) {
                            this.cooperado = data;

                            if (!this.cooperado.cidadePrevisaoTempo) {
                                this.cooperado.cidadePrevisaoTempo = 'Londrina-PR';
                            }

                            this.ativo = this.ativoOpcoes.find(item => {
                                return item.valor === data.ativo
                            });
                        } else {
                            this.voltar();
                        }
                    })
                    .catch(error => {
                        this.voltar();
                    });
            },

            buscarTodasCidades() {
                CidadeService.buscarTodasCidades()
                    .then( ({data}) => {
                        if (data) {
                            this.cidadesPrevisaoTempo = data
                        }
                    })
                    .catch(error => {
                        this.voltar();
                    });
            },

            buscarCooperadoCidadesPrevisaoTempo() {
                CooperadoCidadePrevisaoTempoService.buscarCooperadoCidadesPrevisaoTempo(this.matricula)
                    .then( ({data}) => {
                        if (data) {
                            this.cooperadoCidadesPrevisaoTempo = data
                        } else {
                            this.cooperadoCidadesPrevisaoTempo = [
                                {
                                    'codigo': 'Londrina-PR',
                                    'id': 28,
                                    'nome': 'Londrina PR'
                                }
                            ]
                        }
                    })
                    .catch(error => {
                        this.voltar();
                    });
            },
            
            voltar() {
                this.$router.push(`/cooperados`);
            },
            
            compararSenhaEConfirmarSenha() {
                let isValid = true;
                
                if (this.cooperado) {
                    if (this.senha && this.confirmarSenha && this.senha != this.confirmarSenha) {
                        this.confirmarSenhaInvalida = true;
                        
                        this.abrirToast(this.toastTipoErro,
                                this.toastTituloFalha, 
                                `Senha e Confirmar Senha estão diferente!`, 
                                this.toastLife);
                        
                        isValid = false;
                        return;
                    } else if (this.senha && !this.confirmarSenha) {
                        this.confirmarSenhaInvalida = true;
                        isValid = false;
                    } else if (!this.senha && this.confirmarSenha) {
                        this.senhaInvalida = true;
                        isValid = false;
                    } else {
                        this.cooperado.senhaSha1 = this.senha;
                    }
                }

                return isValid;
            },
            
            validarCampos() {
                let isValid = true;

                if (!this.cooperado.email) {
                    this.emailInvalido = true;
                    isValid = false;
                }

                if (!this.cooperado.celular) {
                    this.celularInvalido = true;
                    isValid = false;
                }

                if (!isValid) {
                    this.abrirToast(this.toastTipoErro, this.toastTituloFalha, 'Preencher os campos obrigatórios.', this.toastLife);
                    return;
                }

                return isValid;
            },
            
            salvar() {
                if (!this.validarCampos()) return;
                if (!this.compararSenhaEConfirmarSenha()) return;
                
                if (this.ativo.valor) {
                    this.cooperado.dataInativacao = null;
                } else {
                    this.cooperado.dataInativacao = new Date();
                }

                CooperadoService.alterar(this.cooperado)
                    .then(response => {
                        this.salvarCooperadoCidadePrevisaoTempo();
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, this.toastDetalheAtualizacaoFalha, this.toastLife);
                    });
            },
            
            salvarCooperadoCidadePrevisaoTempo() {
                let cooperadoCidadesPrevisaoTempoDTO = {
                    'matricula': this.matricula,
                    'cidades': this.cooperadoCidadesPrevisaoTempo
                }

                CooperadoCidadePrevisaoTempoService.salvarCooperadoCidadesPrevisaoTempo(cooperadoCidadesPrevisaoTempoDTO)
                    .then(response => {
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, this.toastDetalheAtuazlizacaoSucesso, this.toastLife);

                        setTimeout(() => {
                            this.voltar();
                        }, "1000");
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, this.toastDetalheAtualizacaoFalha, this.toastLife);
                    });
            },
            
            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },
            formatDate(data) {
                return Formatacao.formatDate(data);
            },
            getCidadePrevisaoTempo(codigoCidade) {
                this.cooperado.cidadePrevisaoTempo = codigoCidade;
            },
        },
        components: { CidadeDropDown },
    }
</script>

<style>
    .break-flex-row {
        flex-basis: 100%;
        height: 0;
    }
</style>
