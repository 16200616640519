<template>
    <AutoComplete
        placeholder="Cidade"
        :dropdown="true"
        :minLength="3"
        v-model="cidadeSelecionada"
        :suggestions="cidadesFiltradas"
        @complete="searchCidades($event)"
        @item-select="selecionarCidade"
        field="nome"
        :class="classe"
    />
</template>

<script>
    import CidadeService from '../service/CidadeService';

    export default {
        name: 'CidadeDropDown', 
        emits: ['aoSelecionarCidade'],
        data() {
            return {
				cidadeSelecionada: null,
				cidadesFiltradas: [],
            }
        },
        props: {
            codigoCidade: {
                type: String,
				required: true
            },
            classe: {
                type: String,
                required: false
            }
        },

        mounted() {
            if (this.codigoCidade) {
                CidadeService.getCidadePorCodigo(this.codigoCidade).then(({ data }) => {
                    this.cidadeSelecionada = data;
                });
            }
        },

        methods: {
            searchCidades(event) {
                setTimeout(() => {
                    const consulta = !event.query.trim().length ? 'a' : event.query.trim();

                    CidadeService.getCidadePorNome(consulta).then(({ data }) => {
                        this.cidadesFiltradas = [...data];
                    });
				}, 250);
            },

            selecionarCidade() {
                if (this.cidadeSelecionada) {
                    const codigoCidade = this.cidadeSelecionada.codigo;
                    this.$emit('aoSelecionarCidade', codigoCidade);
                }
            },
        },        
    }
</script>
