<template>
    <div class="card">
        <Toast />
        <h5>Cooperado</h5>

        <DataTable :value="cooperados" 
                class="p-datatable-gridlines" 
                dataKey="id" 
                v-model:filters="filters1" 
                filterDisplay="menu" 
                :rowHover="true" 
                :loading="loading" 
                responsiveLayout="scroll"
				:globalFilterFields="['matricula', 'nome', 'estabelecimento.nome']" 
                @sort="onSort($event)" 
                sortField="nome" 
                :sortOrder="1" >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <div>
                        <Button type="button" 
                                icon="pi pi-filter-slash" 
                                label="Limpar" 
                                class="p-button-outlined mb-2" 
                                @click="clearFilter1() "/>
                        <Button type="button" 
                                icon="pi pi-check-circle" 
                                label="Listar ativos" 
                                class="p-button-outlined p-button-info mb-2 ml-3" 
                                @click="listarAtivos()" 
                                v-if="ativoOuInativo == null || !ativoOuInativo" />
                        <Button type="button" 
                                icon="pi pi-ban" 
                                label="Listar inativos" 
                                class="p-button-outlined p-button-danger mb-2 ml-3" 
                                @click="listarInativos()" 
                                v-if="ativoOuInativo" />
                    </div>
                    <div>
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="palavraChave" 
                                    placeholder="Matrícula, Nome ou Regional" 
                                    style="width: 250px;" 
                                    @keyup="buscarCooperados()" />
                        </span>
                    </div>
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="matricula" header="Matrícula" style="width: 4rem;" sortable />
            <Column field="nome" header="Nome" style="width: 10rem;" :sortable="true" />
            <Column field="estabelecimento.nome" header="Regional" style="width: 10rem;" sortable />
            <Column field="ativo" header="Status" style="width: 4rem; text-align: center;">
                <template #body="{data}">
                    <span v-if="data.ativo" class="product-badge status-instock">ATIVO</span>
                    <span v-if="!data.ativo" class="product-badge status-outofstock">INATIVO</span>
                </template>
            </Column>
            <Column style="width: 2rem; text-align: center;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button icon="pi pi-list" 
                            title="Histórico de Acesso" 
                            class="p-button-rounded p-button-warning mr-2" 
                            @click="abrirHistoricoAcesso(data.matricula)" />
                    </div>
                </template>
            </Column>
            <Column style="width: 4rem; text-align: center;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button icon="pi pi-pencil" 
                            title="Alterar" 
                            class="p-button-rounded p-button-success mr-2" 
                            @click="alterar(data.matricula)" />
                        <Button v-if="!data.ativo" 
                            title="Ativar" 
                            icon="pi pi-check" 
                            class="p-button-rounded p-button-info" 
                            @click="confirmarAtivarOuInativar(data)" />
                        <Button v-if="data.ativo" 
                            title="Inativar" 
                            icon="pi pi-times" 
                            class="p-button-rounded p-button-danger" 
                            @click="confirmarAtivarOuInativar(data)" />
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            :rows="qtdRegistro"
            :totalRecords="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />
    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span>Deseja <strong>{{ cooperado.ativo ? 'Inativar' : 'ativar' }}</strong> o Cooperado <strong>{{ cooperado.nome }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <CooperadoHistoricoAcesso v-if="historicoDialog" :matricula="matricula" 
        :visivel="historicoDialog" 
        @fechar="fecharHistoricoAcesso()" />
</template>

<script>
    import CooperadoService from '../../service/CooperadoService';
    import CooperadoHistoricoAcesso from './CooperadoHistoricoAcesso.vue';

    export default {
    name: "Cooperados",
    data() {
        return {
            cooperados: [],
            filters1: null,
            loading: false,
            totalRegistro: 0,
            qtdRegistro: 10,
            pagina: 0,
            ativarOuInativarDialog: false,
            disabled: true,
            cooperado: {},
            toastTipoErro: "error",
            toastTituloFalha: "Falha",
            toastTipoSucesso: "success",
            toastTituloSucesso: "Sucesso",
            toastLife: 3000,
            matricula: null,
            historicoDialog: false,
            palavraChave: '',
            ativoOuInativo: null,
            ordenacao: '',
        };
    },
    created() {
        this.buscarCooperados();
    },
    methods: {
        carregarLoading() {
            this.loading = !this.loading;
        },
        
        clearFilter1() {
            this.palavraChave = '';
            this.cooperados = [];
            this.ativoOuInativo = null;
            this.botaoAtivoInativo = 'Listar ativos';
            this.ordenacao = '';
            this.buscarCooperados();
        },
        onPage(event) {
            this.pagina = event.page;
            this.qtdRegistro = event.rows;
            this.buscarCooperados();
        },
        abrirToast(tipo, titulo, descricao, tempo) {
            this.$toast.add({
                severity: tipo,
                summary: titulo,
                detail: descricao,
                life: tempo
            });
        },
        confirmarAtivarOuInativar(cooperado) {
            this.cooperado = cooperado;
            this.ativarOuInativarDialog = true;
        },
        ativarOuInativar() {
            if (this.cooperado.ativo) {
                CooperadoService.inativar(this.cooperado.matricula)
                    .then(response => {
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `O cooperado ${this.cooperado.nome} foi inativado com sucesso!`, this.toastLife);
                        this.buscarCooperados();
                        this.ativarOuInativarDialog = false;
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTipoErro, `Falha ao inativar o cooperado ${this.cooperado.nome}!`, this.toastLife);
                    });
            }
            else {
                CooperadoService.ativar(this.cooperado.matricula)
                    .then(response => {
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `O cooperado ${this.cooperado.nome} foi ativado com sucesso!`, this.toastLife);
                        this.buscarCooperados();
                        this.ativarOuInativarDialog = false;
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTipoErro, `Falha ao inativar o cooperado ${this.cooperado.nome}!`, this.toastLife);
                    });
            }
        },
        alterar(matricula) {
            this.$router.push(`/cooperados/${matricula}`);
        },
        abrirHistoricoAcesso(matricula) {
            this.historicoDialog = true;
            this.matricula = matricula;
        },

        fecharHistoricoAcesso() {
            this.historicoDialog = false;
            this.matricula = null;
        },

        buscarCooperados() {
            if ((this.palavraChave.length > 2 
                    || (!isNaN(this.palavraChave) && this.palavraChave != ''))
                    && (this.ativoOuInativo != '' 
                    || this.ativoOuInativo != null)) {
                this.filtrarCooperados();
            } else if (this.palavraChave == '') {
                this.filtrarCooperados();
            }
        },

        filtrarCooperados() {
            this.carregarLoading();
            let filtro = {
                'palavraChave': this.palavraChave,
                'ativo': this.ativoOuInativo,
            }
            let params = {
                'page': this.pagina,
                'size': this.qtdRegistro,
                'sort': this.ordenacao,
            }
            CooperadoService.buscarCooperados(filtro, params)
                .then(({ data }) => {
                    if (data) {
                        this.cooperados = data.content;
                        this.qtdRegistro = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                        this.carregarLoading();
                    }
                });
        },

        listarAtivos() {
            this.ativoOuInativo = true;
            this.filtrarCooperados();
        },

        listarInativos() {
            this.ativoOuInativo = false;
            this.filtrarCooperados();
        },

        onSort(event) {
            let direcao = 'ASC';
            if (event.sortOrder == -1) {
                direcao = 'DESC';
            }
            if (event.sortField.toLowerCase() == 'estabelecimento.nome') {
                event.sortField = 'estabelecimento';
            }
            this.ordenacao = event.sortField + ',' + direcao;

            this.filtrarCooperados();
        },
    },
    components: { CooperadoHistoricoAcesso }
}
</script>

<style scoped lang="scss">
    @import '../../assets/demo/styles/badges.scss';
</style>
